.preview-container{
  background-color: #fff;
  border-color: #8c8c8c!important;
}
.attachment-preview{
  position: relative;
}
.attachment-preview--caption{
  padding: 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #d1d1d1cf;
  font-size: 12px;
}
.attachment-preview--img{
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.fm-core-ui .modal-body{
  height: calc(100% - 145px);
}

.fm-core-ui .attachment-preview {
    position: relative;
    box-shadow: inset 0 0 15px rgb(0 0 0 / 10%), inset 0 0 0 1px rgb(0 0 0 / 5%);
    background: #f0f0f1;
    cursor: pointer;
}

.fm-core-ui {
  .tree-structure{
    .folder-structure-item{
      list-style: none;
      padding-left: 1rem;
      margin-bottom: 0.4rem;
      &.active{
        background-color: antiquewhite;
      }

    }
  }
  .attachments-wrapper{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    tbody{
      tr{
        cursor: pointer;
        &.selected{
          box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 5px #2271b1;
        }
        td:nth-child(1){
          max-width: 240px;
          .attachment-name{
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
      }
    }
  }
  .attachment {
      position: relative;
      float: left;
      padding: 8px;
      margin: 0;
      color: #3c434a;
      cursor: pointer;
      list-style: none;
      text-align: center;
      -webkit-user-select: none;
      user-select: none;
      // width: 25%;
      width: 100%;
      box-sizing: border-box;
      &.selected {
          box-shadow: inset 0 0 0 3px #fff, inset 0 0 0 7px #2271b1;
          &:focus{
            box-shadow: inset 0 0 2px 3px #fff, inset 0 0 0 7px #4f94d4;
            outline: 2px solid transparent;
            outline-offset: -6px;
          }
      }
      &:focus{
        box-shadow: inset 0 0 2px 3px #fff, inset 0 0 0 7px #4f94d4;
        outline: 2px solid transparent;
        outline-offset: -6px;
      }
      .thumbnail{
        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        transition: opacity .1s;
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
            overflow: hidden;
        }
      }
      .check {
        position: absolute;
        top: -5px;
        right: -5px;
        display: none;
        padding: 3px;
        border: 3px solid #fff;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: #005dac;
      }
  }
  .attachment-preview{
    &:before {
        content: "";
        display: block;
        padding-top: 100%;
    }
  }
  &.selected {
    .check {
      display: flex;
    }
  }

  .attachments-wrapper{
    overflow: auto;
    height: 80%;
  }

  .media-sidebar{
    // padding: 0 16px;
    background: #f6f7f7;
    border-left: 1px solid #dcdcde;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .tab-content{
    height: calc(100% - 27px);
  }
  .ReactCrop__child-wrapper{
      height: 100%;
  }
}
