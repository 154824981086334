.ant-select-without-border {
  &.ant-select {
    .ant-select-selector {
      border: 0 solid transparent;
      padding: 0px;
      height: 24px;
    }
  }
  &.ant-select-focused {
    box-shadow: none !important;
    height: 24px;

    &.ant-select {
      .ant-select-selector {
        box-shadow: none !important;
        height: 24px;
      }
    }
  }
}

.ant-select-search {
  &.ant-select {
    .ant-select-selector {
      .ant-select-selection-search {
        inset-inline-start: 0;
        inset-inline-end: 0;
      }
    }
  }
}
