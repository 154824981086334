.swiper-button-next, .swiper-button-prev{
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  font-size: 10px;
  &:after{
    font-size: 17px;
    font-weight: bold;
  }
}
