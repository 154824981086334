@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap");

// Bootstrap mixins and functions
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";

// Change variables here
@import "adminv2/variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Antd
@import "adminv2/antd";

// Vendor
@import "adminv2/vendor";
@import "plugins/swiper";

@import "./node_modules/ckeditor.scss";
@import "leaflet/dist/leaflet.css";

// Mixins & functions
@import "adminv2/mixins";
@import "adminv2/functions";

// Utilities
@import "adminv2/reboot";
@import "adminv2/utilities";

// Layout
@import "adminv2/layout";

// Components
@import "adminv2/components";

@import "~swiper/swiper-bundle.min.css";

$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

@import "plugins/timeline";

// write your custom styles here!
.collection-seo-form {
  font-size: 12px;
}
.common-form .accordion-button {
  font-size: 13px;
  padding: 0.85rem 1.25rem;
}
.common-form .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.675rem;
}

.collection-seo-form .nav-tabs .nav-item {
  font-size: 10px;
}

.collection-seo--preview-title {
  font-size: 13px;
  color: #1a0dab;
  overflow: hidden;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.collection-seo--preview-description {
  word-wrap: break-word;
  color: #006621;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.collection-seo--preview-url {
  color: #545454;
  font-size: 11px;
  font-weight: 400;
}

@for $i from 1 through 6 {
  .td-#{$i * 100} {
    white-space: unset !important;
    width: #{$i * 100}px;
    min-width: #{$i * 100}px;
  }
}

.modal-90w {
  min-width: 90vw;
}
.modal-90h {
  height: 90vh;
  .modal-content {
    height: 100%;
  }
}

.permission-list-container {
  overflow: hidden;
  overflow-y: scroll;
  height: 350px;
}

.total-Total {
  font-size: 20px !important;
  font-weight: bold;
}

th,
td {
  &.handle {
    cursor: move;
    width: 35px;
    vertical-align: middle;
    text-align: center;
    i {
      pointer-events: none;
    }
  }
  &.action {
    vertical-align: middle;
    text-align: center;
  }
}

.well {
  height: 200px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #f8f9fa !important;
  border: 1px solid #dee2e6 !important;
  .well-item {
    padding: 5px;
  }
}

.handle {
  cursor: grab;
}

.total-price {
  font-size: 17px;
  font-weight: 600;
  color: #c84242;
}
.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.h-32 {
  height: 32px;
}
