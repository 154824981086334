ul.timeline {
    list-style-type: none;
    position: relative;
    &:before {
        content: ' ';
        background: #d4d9df;
        display: inline-block;
        position: absolute;
        left: 29px;
        width: 2px;
        height: 100%;
        z-index: 400;
    }
    > li {
        margin: 20px 0;
        padding-left: 30px;
        > svg{
          width: 40px;
          height: 40px;
          position: absolute;
          left: 10px;
          top: -3px;
          z-index: 1000;
        }
        &:before {
            content: ' ';
            background: white;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid #22c0e8;
            left: 20px;
            width: 20px;
            height: 20px;
            z-index: 400;
        }
    }
}
