.component_dashboard--realtimeServerInfo {
    background: linear-gradient(60deg, #000, rgb(27, 34, 56));
    color: #fff;
    font-weight: bold;
}

.memory_usage {
  > div {
      width: 20%;
  }
}
